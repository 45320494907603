<script>
import api from '@/utils/api.js'
export default {
  name: 'Login',
  data: () => ({
    error: null,
    password: null,
    username: null,
    showPassword: false,
    submitted: false
  }),
  computed: {
    btnState() {
      if (this.submitted) return { loading: true }
      if (this.username === null || this.password === null)
        return { disabled: true }
      return {}
    }
  },
  methods: {
    alertText() {
      //split 1 sentence per line
      return this.error
        ?.split('. ')
        .map((l) => `<div>${l}${l.slice(-1) === '.' ? '' : '.'}</div>`)
        .join('')
    },
    onLogin(login = {}) {
      //todo: route depending on role ?
      const params = new URLSearchParams(
        window.location.href.split('?')[1] || ''
      )
      const dest = params.get('dest') || null
      if (dest) window.location.replace(decodeURI(dest))
      else {
        this.$store.dispatch('setUserdata', login)
        this.$router.push({ name: 'home' })
      }
    },
    async submitLogin() {
      this.error = null
      if (!this.username || !this.password) {
        this.error = 'Please enter a valid username and password.'
        return
      }
      this.submitted = true
      try {
        const login = await api.post(
          process.env.VUE_APP_AUTH_URL,
          JSON.stringify({
            username: this.username,
            password: this.password
          }),
          {
            withCredentials: true,
            credentials: 'include'
          },
          false
        )
        if (login?.id) this.onLogin(login)
      } catch (err) {
        this.error =
          err?.response?.data?.message ||
          'Error encountered submitting request.'
      } finally {
        this.submitted = false
      }
    }
  },
  mounted() {
    //TODO: only if not prefilled?
    // console.log(this.username)
    this.$nextTick(this.$refs.userField.focus())
  }
}
</script>
<template>
  <v-sheet
    width="100%"
    height="100%"
    class="background-sheet d-flex flex-column align-center justify-start"
  >
    <v-card
      elevation="24"
      max-width="80vw"
      outlined
      raised
      tile
      width="600"
      class="login-card"
    >
      <v-card-title
        class="text-h4 flex-column font-weight-bold primary--text justify-center"
        >WELCOME
        <v-divider width="20%" class="mt-4"></v-divider>
      </v-card-title>

      <form @submit.prevent="submitLogin">
        <v-card-text class="pa-6 pt-0">
          <v-sheet
            color="transparent"
            height="2rem"
            transition="scale-transition"
            width="100%"
            class="d-flex flex-column align-center justify-center mb-2"
          >
            <v-alert
              :value="error !== null"
              dense
              outlined
              text
              type="error"
              width="100%"
            >
              <v-sheet
                v-html="alertText()"
                color="transparent"
                class="error--text"
                width="100%"
              >
              </v-sheet>
            </v-alert>
          </v-sheet>
          <v-text-field
            v-model="username"
            ref="userField"
            label="Username"
            prepend-icon="mdi mdi-account"
            type="text"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"
            label="Password"
            prepend-icon="mdi mdi-key"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
        </v-card-text>
        <v-card-actions
          class="d-flex flex-column align-center justify-center px-8 py-8"
        >
          <v-btn
            v-bind="btnState"
            block
            color="primary"
            :loading="submitted"
            tile
            type="submit"
            @click.prevent="submitLogin"
          >
            LOGIN
          </v-btn>
          <p class="my-2">-or-</p>
          <v-btn block color="primary" to="/login/microsoft" tile>
            <v-icon left>mdi-microsoft-office</v-icon> LOGIN MICROSOFT
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-sheet>
</template>
<style lang="scss" scoped>
.background-sheet {
  background-color: #00006624;
  background-image: url('https://media.eipl.org/site/images/login_background.jpg');
  background-size: cover;
  background-position: center, center;
  background-blend-mode: overlay;
}

.login-card {
  top: 20vh;
}
</style>
