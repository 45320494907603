var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('h1', {
    staticClass: "text-h5 primary--text mb-4"
  }, [_vm._v("MENU")]), _c('v-card', {
    style: {
      height: "calc(87vh - ".concat(_vm.$vuetify.application.top, "px)")
    },
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [_c('v-card-text', {
    style: {
      height: "calc(85vh - ".concat(_vm.$vuetify.application.top, "px)"),
      overflow: 'auto',
      position: 'absolute'
    }
  }, [_c('v-treeview', {
    attrs: {
      "active": _vm.navTreeActive,
      "open": _vm.navTreeOpen,
      "activatable": "",
      "active-class": "primary--text",
      "dense": "",
      "transition": "",
      "items": _vm.nav,
      "item-children": "children",
      "item-text": "text",
      "item-key": "id",
      "return-object": ""
    },
    on: {
      "update:active": [function ($event) {
        _vm.navTreeActive = $event;
      }, _vm.onActiveUpdate],
      "update:open": function updateOpen($event) {
        _vm.navTreeOpen = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn(_ref) {
        var _vm$navTreeActive, _vm$navTreeActive$;

        var item = _ref.item;
        return [_c('v-icon', {
          attrs: {
            "color": item.children && _vm.activeTreePath.includes(item.path) || item.id === ((_vm$navTreeActive = _vm.navTreeActive) === null || _vm$navTreeActive === void 0 ? void 0 : (_vm$navTreeActive$ = _vm$navTreeActive[0]) === null || _vm$navTreeActive$ === void 0 ? void 0 : _vm$navTreeActive$.id) ? 'primary' : '',
            "small": ""
          }
        }, [_vm._v("mdi-" + _vm._s(item.icon || 'link'))])];
      }
    }, {
      key: "label",
      fn: function fn(_ref2) {
        var _vm$navTreeActive2, _vm$navTreeActive2$;

        var item = _ref2.item;
        return [_c('span', {
          staticClass: "font-weight-bold",
          class: item.children && _vm.activeTreePath.includes(item.path) || item.id === ((_vm$navTreeActive2 = _vm.navTreeActive) === null || _vm$navTreeActive2 === void 0 ? void 0 : (_vm$navTreeActive2$ = _vm$navTreeActive2[0]) === null || _vm$navTreeActive2$ === void 0 ? void 0 : _vm$navTreeActive2$.id) ? 'primary--text' : '',
          attrs: {
            "title": item.text
          }
        }, [_vm._v(" " + _vm._s(item.text))])];
      }
    }]),
    model: {
      value: _vm.tree,
      callback: function callback($$v) {
        _vm.tree = $$v;
      },
      expression: "tree"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "column",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-space-between mb-4",
    attrs: {
      "color": "transparent"
    }
  }, [_c('navigation-item-header', {
    attrs: {
      "navItem": _vm.navTreeActive[0] || {},
      "itemsById": _vm.navById
    }
  }), _c('v-sheet', {
    staticClass: "d-flex align-center justify-end",
    attrs: {
      "color": "transparent"
    }
  }, [!this.navTreeActive[0] || this.activeHasChildren ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "tile": ""
    },
    on: {
      "click": _vm.addNavItem
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v("ADD ")], 1) : _vm._e()], 1)], 1), _c('v-card', {
    style: {
      height: "calc(87vh - ".concat(_vm.$vuetify.application.top, "px)")
    },
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-text', {
    style: {
      height: "calc(85vh - ".concat(_vm.$vuetify.application.top, "px)"),
      overflow: 'auto',
      position: 'absolute'
    }
  }, [!_vm.activeTreePath || _vm.activeHasChildren ? _c('v-list', {
    attrs: {
      "two-line": ""
    }
  }, [_vm._l(_vm.itemsList, function (child) {
    return [_c('v-list-item', {
      key: child.text
    }, [_c('v-list-item-content', {
      staticClass: "primary--text"
    }, [_c('v-list-item-title', {
      staticClass: "d-flex align-center"
    }, [child.icon ? _c('v-icon', {
      attrs: {
        "color": "primary",
        "left": ""
      }
    }, [_vm._v(" mdi-" + _vm._s(child.icon) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(child.text))], 1), _c('v-list-item-subtitle', {
      domProps: {
        "innerHTML": _vm._s(_vm.itemSubtitle(child))
      }
    })], 1), _c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "color": "error"
      },
      domProps: {
        "textContent": _vm._s('mdi-delete')
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteItem(child);
        }
      }
    })], 1), _c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "color": "warning"
      },
      domProps: {
        "textContent": _vm._s('mdi-pencil')
      },
      on: {
        "click": function click($event) {
          return _vm.onEditItem(child);
        }
      }
    })], 1)], 1), _c('v-divider', {
      key: 'd' + child.text
    })];
  })], 2) : _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "text"
    },
    model: {
      value: _vm.itemEditing.text,
      callback: function callback($$v) {
        _vm.$set(_vm.itemEditing, "text", $$v);
      },
      expression: "itemEditing.text"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "url"
    },
    model: {
      value: _vm.itemEditing.href,
      callback: function callback($$v) {
        _vm.$set(_vm.itemEditing, "href", $$v);
      },
      expression: "itemEditing.href"
    }
  })], 1)]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "disabled": !_vm.edittingIsChanged,
      "tile": "",
      "color": "warning"
    },
    on: {
      "click": _vm.revertItemEditing
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-undo")]), _vm._v("REVERT ")], 1), _c('v-btn', {
    attrs: {
      "disabled": !_vm.edittingIsChanged,
      "tile": "",
      "color": "success"
    },
    on: {
      "click": _vm.saveItemEditing
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-content-save")]), _vm._v("SAVE ")], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "500"
    },
    model: {
      value: _vm.modal,
      callback: function callback($$v) {
        _vm.modal = $$v;
      },
      expression: "modal"
    }
  }, [_vm.modal ? _c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5 align-center justify-center primary secondary--text"
  }, [_vm._v(" ADD NAV ITEM ")]), _c('v-card-text', {
    staticClass: "pa-4",
    staticStyle: {
      "height": "60vh"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Add a new item to:")]), _c('navigation-item-header', {
    attrs: {
      "navItem": _vm.navTreeActive[0] || {},
      "itemsById": _vm.navById
    }
  })], 1)], 1), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.saveNewItem.apply(null, arguments);
      }
    }
  }), _c('v-card-text', [_c('p', {
    staticClass: "primary--text mb-0"
  }, [_vm._v("Item type:")]), _c('v-radio-group', {
    attrs: {
      "messages": _vm.newItemMenuTypeDisabled() ? 'Nav menu cannot exceed three layers deep.' : '',
      "row": ""
    },
    model: {
      value: _vm.newItem.type,
      callback: function callback($$v) {
        _vm.$set(_vm.newItem, "type", $$v);
      },
      expression: "newItem.type"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Link",
      "value": "link"
    }
  }), _c('v-radio', {
    attrs: {
      "disabled": _vm.newItemMenuTypeDisabled(),
      "label": "Menu",
      "value": "menu"
    }
  })], 1), _c('v-text-field', {
    attrs: {
      "label": "Text"
    },
    model: {
      value: _vm.newItem.text,
      callback: function callback($$v) {
        _vm.$set(_vm.newItem, "text", $$v);
      },
      expression: "newItem.text"
    }
  }), _vm.newItem.type === 'link' ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "clearable": "",
      "label": "Target Url",
      "type": "url"
    },
    model: {
      value: _vm.newItem.href,
      callback: function callback($$v) {
        _vm.$set(_vm.newItem, "href", $$v);
      },
      expression: "newItem.href"
    }
  })], 1) : _vm._e(), _vm.newItem.type === 'menu' ? _c('v-card', {
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Icon (picker):")]), _c('v-icon', [_vm._v("mdi-link-variant")])], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.modal = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "color": "success",
      "text": ""
    },
    on: {
      "click": _vm.saveNavItem
    }
  }, [_vm._v(" Save ")])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }