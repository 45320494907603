<script>
export default {
  name: 'alert-manage-card',
  props: {
    isDeleting: {
      type: Boolean,
      default: () => false
    },
    isEditing: {
      type: Boolean,
      default: () => false
    },
    isToggling: {
      type: Boolean,
      default: () => false
    },
    isSaving: {
      type: Boolean,
      default: () => false
    },
    item: {
      type: Object,
      default: () => ({
        text: 'New Alert',
        title: 'New Alert',
        type: 'info'
      })
    },
    toggleLocked: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    confDelete: false,
    editing: {
      text: '',
      title: '',
      type: 'info'
    }
  }),
  computed: {
    formErrors() {
      if (!this.editing.title) {
        return 'Title required.'
      }
      if (!this.editing.text) {
        return 'Alert message text required.'
      }
      if (!this.editing.type) {
        return 'Alert type required.'
      }
      return null
    }
  },
  methods: {
    onEditBtn() {
      //Btn has two states..
      //edit or save
      if (this.isEditing) {
        this.$emit('save', { ...this.editing })
      } else {
        this.$emit('edit', this.editing.id)
        // this.isEditing = true
        this.editing = { ...this.item }
      }
    },
    onConfDeleteBtn() {
      this.$emit('delete', this.item.id)
    },
    onDeleteBtn() {
      //Btn has three states..
      //cancel edit, delete or confirm delete
      if (this.confDelete) {
        this.confDelete = false
        return
      }

      if (this.isEditing) {
        // this.isEditing = false
        this.$emit('edit', this.editing.id)
        this.editing = { ...this.item }
      } else {
        this.confDelete = true
      }
    },
    onToggle() {
      // The library will be closed Tuesday 12/24 and Wednesday 12/25 in observance of Christmas.
      this.$emit('toggle', this.item.id)
      // this.editing = {
      //   ...this.editing,
      //   enabled: this.editing?.enabled == 1 ? 0 : 1
      // }
    }
  },
  created() {
    this.editing = { ...this.item }
  }
}
</script>

<template>
  <!-- TODO TEST WHAT HAPPENS IF WE TOGGLE ENABLE/DISABLE WHILE EDITING
A DIFFERENT ALERT, MAY NEED TO DISABLE TOGGLES FOR ALL WHILE EDITING 
ANY 
-->
  <v-card outlined>
    <v-card-title
      class="title d-flex flex-nowrap justify-space-between primary--text text-h6 text-md-h4 text-no-wrap"
    >
      <v-text-field
        v-if="isEditing"
        v-model="editing.title"
        hide-details
        label="Title"
      ></v-text-field>
      <span v-else class="text-wrap">
        {{ editing?.title || '[untitled]' }}</span
      >
      <v-tooltip color="primary" top>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-switch
              :disabled="toggleLocked || isToggling"
              :input-value="item.enabled == 1"
              :loading="isToggling"
              @change="onToggle"
              class="mt-0"
            ></v-switch>
          </div>
        </template>
        <span>{{
          toggleLocked
            ? `Disabled while modifying alerts`
            : item.enabled
            ? 'Disable alert'
            : 'Show alert'
        }}</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text v-if="isEditing">
      <v-sheet max-width="200px">
        <v-select
          v-if="isEditing"
          v-model="editing.type"
          hide-details
          :items="['error', 'info', 'success', 'warning']"
          label="Type"
        >
          <template v-slot:item="{ item }">
            <span :class="`${item}--text font-weight-bold`">{{
              item.toUpperCase()
            }}</span>
          </template>
          <template v-slot:selection="{ item }">
            <span :class="`${item}--text font-weight-bold`">{{
              item.toUpperCase()
            }}</span>
          </template>
        </v-select>
        <v-chip v-else :color="editing.type" label small>{{
          editing.type.toUpperCase()
        }}</v-chip>
      </v-sheet>
      <v-sheet class="mt-4">
        <v-text-field
          v-if="isEditing"
          v-model="editing.text"
          hide-details
          label="Text"
          outlined
        ></v-text-field>
        <p v-else class="mt-2 font-weight-bold">{{ editing.text }}</p>
      </v-sheet>
    </v-card-text>

    <v-card-text class="py-0">
      <p v-if="isEditing" class="mb-2 font-weight-bold">Preview</p>
      <v-alert :type="editing.type">{{ editing.text }}</v-alert>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :color="isEditing || confDelete ? 'warning' : 'error'"
        :disabled="isSaving"
        text
        @click="onDeleteBtn"
        >{{ isEditing || confDelete ? 'CANCEL' : 'DELETE' }}</v-btn
      >
      <v-btn
        v-if="confDelete"
        color="error"
        :loading="isDeleting"
        @click="onConfDeleteBtn"
        >CONFIRM DELETE</v-btn
      >
      <v-spacer></v-spacer>

      <v-tooltip
        :color="!isEditing ? 'primary' : !formErrors ? 'success' : 'error'"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              :color="isEditing ? 'success' : 'primary'"
              :disabled="isEditing && !!formErrors"
              :loading="isEditing && isSaving"
              text
              @click="onEditBtn"
              class="font-weight-bold"
              >{{ isEditing ? 'SAVE' : 'EDIT' }}</v-btn
            >
          </div>
        </template>
        <span>{{
          !isEditing ? 'Edit alert' : !formErrors ? 'Save changes' : formErrors
        }}</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.title {
  word-break: keep-all;
}
</style>
