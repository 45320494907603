var render = function () {
  var _vm$editing;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title d-flex flex-nowrap justify-space-between primary--text text-h6 text-md-h4 text-no-wrap"
  }, [_vm.isEditing ? _c('v-text-field', {
    attrs: {
      "hide-details": "",
      "label": "Title"
    },
    model: {
      value: _vm.editing.title,
      callback: function callback($$v) {
        _vm.$set(_vm.editing, "title", $$v);
      },
      expression: "editing.title"
    }
  }) : _c('span', {
    staticClass: "text-wrap"
  }, [_vm._v(" " + _vm._s(((_vm$editing = _vm.editing) === null || _vm$editing === void 0 ? void 0 : _vm$editing.title) || '[untitled]'))]), _c('v-tooltip', {
    attrs: {
      "color": "primary",
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-switch', {
          staticClass: "mt-0",
          attrs: {
            "disabled": _vm.toggleLocked || _vm.isToggling,
            "input-value": _vm.item.enabled == 1,
            "loading": _vm.isToggling
          },
          on: {
            "change": _vm.onToggle
          }
        })], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.toggleLocked ? "Disabled while modifying alerts" : _vm.item.enabled ? 'Disable alert' : 'Show alert'))])])], 1), _vm.isEditing ? _c('v-card-text', [_c('v-sheet', {
    attrs: {
      "max-width": "200px"
    }
  }, [_vm.isEditing ? _c('v-select', {
    attrs: {
      "hide-details": "",
      "items": ['error', 'info', 'success', 'warning'],
      "label": "Type"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          class: "".concat(item, "--text font-weight-bold")
        }, [_vm._v(_vm._s(item.toUpperCase()))])];
      }
    }, {
      key: "selection",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          class: "".concat(item, "--text font-weight-bold")
        }, [_vm._v(_vm._s(item.toUpperCase()))])];
      }
    }], null, false, 1084751388),
    model: {
      value: _vm.editing.type,
      callback: function callback($$v) {
        _vm.$set(_vm.editing, "type", $$v);
      },
      expression: "editing.type"
    }
  }) : _c('v-chip', {
    attrs: {
      "color": _vm.editing.type,
      "label": "",
      "small": ""
    }
  }, [_vm._v(_vm._s(_vm.editing.type.toUpperCase()))])], 1), _c('v-sheet', {
    staticClass: "mt-4"
  }, [_vm.isEditing ? _c('v-text-field', {
    attrs: {
      "hide-details": "",
      "label": "Text",
      "outlined": ""
    },
    model: {
      value: _vm.editing.text,
      callback: function callback($$v) {
        _vm.$set(_vm.editing, "text", $$v);
      },
      expression: "editing.text"
    }
  }) : _c('p', {
    staticClass: "mt-2 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.editing.text))])], 1)], 1) : _vm._e(), _c('v-card-text', {
    staticClass: "py-0"
  }, [_vm.isEditing ? _c('p', {
    staticClass: "mb-2 font-weight-bold"
  }, [_vm._v("Preview")]) : _vm._e(), _c('v-alert', {
    attrs: {
      "type": _vm.editing.type
    }
  }, [_vm._v(_vm._s(_vm.editing.text))])], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": _vm.isEditing || _vm.confDelete ? 'warning' : 'error',
      "disabled": _vm.isSaving,
      "text": ""
    },
    on: {
      "click": _vm.onDeleteBtn
    }
  }, [_vm._v(_vm._s(_vm.isEditing || _vm.confDelete ? 'CANCEL' : 'DELETE'))]), _vm.confDelete ? _c('v-btn', {
    attrs: {
      "color": "error",
      "loading": _vm.isDeleting
    },
    on: {
      "click": _vm.onConfDeleteBtn
    }
  }, [_vm._v("CONFIRM DELETE")]) : _vm._e(), _c('v-spacer'), _c('v-tooltip', {
    attrs: {
      "color": !_vm.isEditing ? 'primary' : !_vm.formErrors ? 'success' : 'error',
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on,
            attrs = _ref4.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-btn', {
          staticClass: "font-weight-bold",
          attrs: {
            "color": _vm.isEditing ? 'success' : 'primary',
            "disabled": _vm.isEditing && !!_vm.formErrors,
            "loading": _vm.isEditing && _vm.isSaving,
            "text": ""
          },
          on: {
            "click": _vm.onEditBtn
          }
        }, [_vm._v(_vm._s(_vm.isEditing ? 'SAVE' : 'EDIT'))])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(!_vm.isEditing ? 'Edit alert' : !_vm.formErrors ? 'Save changes' : _vm.formErrors))])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }