<script>
import { mapGetters, mapState } from 'vuex'
import headerMenu from '@/components/header-menu.vue'
import navCms from '@/components/nav-cms.vue'
import navMain from '@/components/nav-main.vue'
// import navItems from '@/data/tempNavData.js'

export default {
  name: 'Header',
  components: { headerMenu, navCms, navMain },
  data: () => ({
    // navItems: navItems
  }),
  computed: {
    ...mapGetters(['isAdmin', 'isAuth', 'settingsByCat']),
    ...mapState({
      app: (state) => state.app,
      user: (state) => state.user
    }),
    breakpoint() {
      return this.$vuetify.breakpoint
    },
    isCms() {
      return this.$route.path.includes('/cms')
    }
  },
  methods: {
    toHome() {
      this.$route.name !== 'login' &&
        this.$route.name !== 'home' &&
        this.$router.push({ name: 'home' })
    },
    toggleSearch() {
      this.$store.dispatch('setSearch', { value: true })
    }
  }
}
</script>
<template>
  <v-app-bar app color="primary" :extension-height="isAuth ? 75 : 0">
    <v-img
      src="https://media.eipl.org/site/images/logos/eipl-square.jpg"
      contain
      height="auto"
      max-width="35"
      @click="toHome"
      class="hoverPointer"
    ></v-img>
    <v-toolbar-title
      @click="toHome"
      class="hoverPointer text-h5 secondary--text font-weight-bold ml-2"
      >{{ app.title || '' }}</v-toolbar-title
    >
    <v-spacer></v-spacer>
    <!-- <v-btn v-if="userData?.id" color="secondary" text @click="$emit('logout')"
      >LOGOUT</v-btn
    > -->
    <v-sheet v-if="isAuth" color="transparent" class="d-flex">
      <v-btn color="secondary" text tile @click="toggleSearch" class="mr-2">
        <v-icon color="secondary">mdi-magnify</v-icon>SEARCH
      </v-btn>
      <header-menu></header-menu>
    </v-sheet>

    <!-- For now, disabling general navigation -->
    <template v-if="isAuth && isCms" v-slot:extension>
      <v-sheet color="transparent" width="100%">
        <v-slide-y-transition>
          <!-- SEARCH DROP DOWN -->
          <search-card v-if="false"></search-card>
        </v-slide-y-transition>
        <!-- TEMP NAV UNTIL WE MAKE NEW APP FOR CMS -->
        <nav-cms v-if="isCms"></nav-cms>
        <!-- <nav-main
          v-else
          :breakpoint="breakpoint"
          :navItems="app.nav"
        ></nav-main> -->
      </v-sheet>
    </template>
  </v-app-bar>
</template>
