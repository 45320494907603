<script>
import AlertManageCard from '../components/alert-manage-card.vue'

import api from '@/utils/api'
export default {
  name: 'communication-page',
  components: { AlertManageCard },
  data: () => ({
    // activeTab: 'alert',
    alerts: [],
    commTypes: ['alerts', 'bulletins', 'notifications'],
    defaults: {
      alerts: {
        enabled: false,
        id: 999999,
        note: '',
        schedule_start: '',
        scedule_end: '',
        text: '',
        title: '',
        type: 'info'
      }
    },
    defaultTab: 'alerts',
    deleting: [],
    editing: [],
    enabling: [],
    newItems: {
      alerts: [],
      bulletins: [],
      notifications: []
    },
    saving: [],
    tabs: [
      {
        disabled: false,
        icon: 'alert',
        text: 'ALERT',
        to: '/cms/communication/alert',
        value: 'alerts'
      },
      {
        disabled: true,
        icon: 'bullhorn-variant',
        text: 'BULLETIN',
        to: '/cms/communication/bulletin',
        value: 'bulletins'
      },
      {
        disabled: true,
        icon: 'bell',
        text: 'NOTIFICATION',
        to: '/cms/communication/notifcation',
        value: 'notifications'
      }
    ]
  }),
  computed: {
    activeTab() {
      const view = this.$route?.params?.view
      if (this.commTypes.includes(view)) return view
      return null
    },
    itemsDisplayed() {
      const componentMap = {
        alerts: {
          comp: AlertManageCard,
          itemKey: 'alerts',
          items: []
        }
      }
      const data = componentMap[this.activeTab] || {}
      const items =
        [...this?.[data.itemKey]].sort((a, b) =>
          a.title.localeCompare(b.title)
        ) || []
      const newItems = [...this?.newItems?.[data.itemKey]] || []
      if (data?.itemKey) {
        return {
          ...componentMap[this.activeTab],
          items: [
            ...newItems.sort((a, b) => b.id.localeCompare(a.id)),
            ...items
          ]
        }
      } else {
        //return what?
        return { items: [] }
      }
    },
    toggleLocked() {
      return (
        this.deleting.length > 0 ||
        this.editing.length > 0 ||
        this.saving.length > 0
      )
    }
  },
  methods: {
    itemIsNew(id) {
      return (
        this.newItems?.[this?.activeTab]?.findIndex((i) => i.id === id) > -1 ||
        false
      )
    },
    onAddItem() {
      const id = `new${new Date().getTime()}`
      this.newItems = {
        ...this.newItems,
        [this.activeTab]: [
          ...this.newItems[this.activeTab],
          { ...this.defaults[this.activeTab], id }
        ]
      }
      this.editing = [...this.editing, id]
    },
    async onItemDelete(id) {
      const isDeleting = this.deleting.includes(id)
      if (isDeleting) {
        //display snackbar... operation pending
        console.warn('Operation: DELETE already pending')
        return
      }
      this.deleting = [...this.deleting, id]
      const URL = `staff/communication/delete/${this.activeTab}/${id}`
      const r = await api.get(URL)
      console.log(r)
      if (r?.success) {
        this[this.activeTab] = [
          ...this[this.activeTab].filter((i) => i.id != id)
        ]
      }
      this.deleting = [...this.deleting].filter((i) => i !== id)
    },
    onItemEdit(id) {
      const isNew = this.itemIsNew(id)
      console.log(this.newItems[this.activeTab])
      console.log(id)
      console.log(isNew)
      if (isNew) {
        this.newItems = {
          ...this.newItems,
          [this.activeTab]: [
            ...this.newItems[this.activeTab].filter((i) => i.id !== id)
          ]
        }
        this.editing = [...this.editing].filter((i) => i !== id)
        return
      }

      const isEditing = this.editing.includes(id)
      if (isEditing) {
        this.editing = [...this.editing].filter((i) => i !== id)
        return
      } else {
        this.editing = [...this.editing, id]
      }
    },
    async onItemSave(item = {}) {
      if (!item?.id) {
        console.log(item)
        console.error('Did not receive an ID when saving item')
        return
      }
      item = { ...item }
      const itemId = item.id

      this.saving = [...this.saving, itemId]

      const isNew = this.itemIsNew(itemId)
      if (isNew) {
        delete item.id
      }

      const URL = `staff/communication`
      const r = await api.post(URL, item)
      console.log(r)
      if (r?.success) {
        this.onItemEdit(itemId)
        // this.editing = [...this.editing].filter((i) => i !== itemId)
        this.saving = [...this.saving].filter((i) => i !== itemId)
        this[this.activeTab] = [
          ...this[this.activeTab].filter((i) => i.id != itemId),
          { ...r.data }
        ]
      }
    },

    async onItemToggle(itemId) {
      if (!itemId) {
        console.log(itemId)
        console.error('Did not receive an ID when toggling item')
        return
      }
      this.enabling = [...this.enabling, itemId]
      const item = this[this.activeTab].find((i) => i.id === itemId)
      console.log(item)
      const newVal = item?.enabled == 1 ? 0 : 1
      const type = this.activeTab.substring(0, this.activeTab.length - 1)
      const r = await api.get(
        `${'staff/communication/toggle/'}${type}/${item.id}/${newVal}`
      )
      if (r?.success) {
        this[this.activeTab] = [...this[this.activeTab]].map((i) => {
          return {
            ...i,
            enabled: i.id === itemId ? newVal : newVal == 1 ? 0 : i.enabled
          }
        })
      }
      this.enabling = [...this.enabling].filter((i) => i !== itemId)
    },
    onTabChange() {
      //do we care about these?
      return
      this.deleting = []
      this.editing = []
      this.loading = []
      this.toggling = []
    },
    statusAddItemS(status, id) {},
    statusRemoveItemS(status, id) {}
  },
  async created() {
    //get communications
    //alerts
    //bulletins
    //notifications
    console.log('created')
    console.log(this.activeTab)
    if (!this.activeTab) {
      //can load last view here if saving
      const base_path = '/cms/communication'
      this.$router.push(`${base_path}/${this.defaultTab}`)
      // this.$router.push(`${this.$route.fullPath}/${this.defaultTab}`)
    }

    const resp = (await api.get('staff/communication')) || {}
    //testing
    // const resp = {}
    for (let comm of this.commTypes) {
      if (resp?.[comm]?.length) {
        this[comm] = [...this[comm], ...resp[comm]]
      }

      // else {
      //   if (this.defaults[comm] && this?.[comm]) {
      //     this[comm] = [...this[comm], this.defaults[comm]]
      //   }
      // }
    }
    //{alerts, bulls, nots} = api.resp
    // for
  }
}
</script>
<template>
  <v-container>
    <v-card class="mb-8">
      <v-card-title class="d-flex align-end justify-space-between pt-0">
        <v-sheet>
          <v-tabs :value="activeTab" color="primary">
            <v-tab
              v-for="tab in tabs"
              :key="tab.text"
              :disabled="tab.disabled"
              :to="tab.to"
              :value="tab.value"
              class="primary--text"
            >
              <v-icon left :small="$vuetify.breakpoint.smAndUp"
                >mdi-{{ tab.icon }}</v-icon
              >{{ $vuetify.breakpoint.xs ? '' : tab.text }}
            </v-tab>
          </v-tabs>
        </v-sheet>

        <v-btn fab small color="primary" @click="onAddItem"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <!-- <v-text-field
          prepend-inner-icon="mdi-file-find-outline"
          solo
          rounded
          class="mt-4"
        ></v-text-field> -->
        <p class="primary--text text-body-2 mb-0">
          Manage public website Alert. This will be displayed at the top of the
          page, under the main header.
        </p>
        <p class="orange--text text-body-2">
          Currently, only one alert will be enable at a time.
        </p>
      </v-card-text>
    </v-card>
    <component
      v-for="item in itemsDisplayed.items"
      :key="item.id"
      :is="itemsDisplayed.comp"
      :isDeleting="deleting.includes(item.id)"
      :isEditing="editing.includes(item.id)"
      :isSaving="saving.includes(item.id)"
      :isToggling="enabling.includes(item.id)"
      :item="item"
      :toggleLocked="toggleLocked"
      @delete="onItemDelete"
      @edit="onItemEdit"
      @save="onItemSave"
      @toggle="onItemToggle"
      class="mt-4"
    />
  </v-container>
</template>

<style lang="scss" scoped></style>
