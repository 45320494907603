var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', {
    staticClass: "mb-8"
  }, [_c('v-card-title', {
    staticClass: "d-flex align-end justify-space-between pt-0"
  }, [_c('v-sheet', [_c('v-tabs', {
    attrs: {
      "value": _vm.activeTab,
      "color": "primary"
    }
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('v-tab', {
      key: tab.text,
      staticClass: "primary--text",
      attrs: {
        "disabled": tab.disabled,
        "to": tab.to,
        "value": tab.value
      }
    }, [_c('v-icon', {
      attrs: {
        "left": "",
        "small": _vm.$vuetify.breakpoint.smAndUp
      }
    }, [_vm._v("mdi-" + _vm._s(tab.icon))]), _vm._v(_vm._s(_vm.$vuetify.breakpoint.xs ? '' : tab.text) + " ")], 1);
  }), 1)], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "small": "",
      "color": "primary"
    },
    on: {
      "click": _vm.onAddItem
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('v-card-text', [_c('p', {
    staticClass: "primary--text text-body-2 mb-0"
  }, [_vm._v(" Manage public website Alert. This will be displayed at the top of the page, under the main header. ")]), _c('p', {
    staticClass: "orange--text text-body-2"
  }, [_vm._v(" Currently, only one alert will be enable at a time. ")])])], 1), _vm._l(_vm.itemsDisplayed.items, function (item) {
    return _c(_vm.itemsDisplayed.comp, {
      key: item.id,
      tag: "component",
      staticClass: "mt-4",
      attrs: {
        "isDeleting": _vm.deleting.includes(item.id),
        "isEditing": _vm.editing.includes(item.id),
        "isSaving": _vm.saving.includes(item.id),
        "isToggling": _vm.enabling.includes(item.id),
        "item": item,
        "toggleLocked": _vm.toggleLocked
      },
      on: {
        "delete": _vm.onItemDelete,
        "edit": _vm.onItemEdit,
        "save": _vm.onItemSave,
        "toggle": _vm.onItemToggle
      }
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }