var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-card-title', {
    staticClass: "d-flex align-end justify-space-between pt-0"
  }, [_c('v-sheet', [_c('v-tabs', {
    attrs: {
      "color": "primary"
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('v-tab', {
      key: tab.text,
      staticClass: "primary--text",
      attrs: {
        "to": tab.to,
        "value": tab.value
      }
    }, [_c('v-icon', {
      attrs: {
        "left": "",
        "small": _vm.$vuetify.breakpoint.smAndUp
      }
    }, [_vm._v("mdi-" + _vm._s(tab.icon))]), _vm._v(_vm._s(_vm.$vuetify.breakpoint.xs ? '' : tab.text) + " ")], 1);
  }), 1)], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "small": "",
      "color": "primary"
    }
  }, [_c('v-icon', [_vm._v("mdi-cloud-upload")])], 1)], 1), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "prepend-inner-icon": "mdi-file-find-outline",
      "solo": "",
      "rounded": ""
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }