<script>
export default {
  name: 'communications-card',
  props: {
    messages: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    temp() {
      return 'hi'
    }
  }
}
</script>
<template>
  <v-card min-height="119" scrollable tile>
    <v-card-title class="text-h6 primary--text">
      <v-icon color="primary" left title="Communications" class="mr-2"
        >mdi-bullhorn-variant</v-icon
      >NOTICES
      <!-- <v-icon color="primary" left>mdi-bullhorn</v-icon> -->
      <!-- <badge :value="messages.length > 0" :badgeContent="messages.length">
        <template v-slot:child>
          <v-icon color="primary">mdi-email-multiple</v-icon> MESSAGES
        </template>
      </badge> -->
    </v-card-title>
    <v-card-text class="scrollable-card primary--text">
      <p class="font-italic mb-0" v-if="messages.length < 1">
        No unread messages
      </p>
      <v-alert
        v-for="message in messages"
        dismissible
        :key="message.id"
        tile
        :type="message.type"
        @input="onMessageDismiss(message.id)"
        >{{ message.message }}</v-alert
      >
    </v-card-text>
  </v-card>
</template>

<style></style>
