<script>
export default {
  name: 'cms-nav-tabs',
  computed: {
    tabs() {
      //TODO:  pull menu on user roles
      return [
        {
          to: '/cms/media',
          text: 'MEDIA',
          icon: 'folder-multiple-image'
        },
        {
          to: '/cms/communication',
          text: 'COMMUNICATION',
          icon: 'message-text'
        },
        {
          to: '/cms/navigation',
          text: 'NAV',
          icon: 'menu-open'
        },

        {
          to: '/cms/pages',
          text: 'PAGES',
          icon: 'text-box-multiple-outline'
        },
        {
          to: '/cms/statistics',
          text: 'STATS',
          icon: 'chart-areaspline'
        }
      ]
    }
  }
}
</script>
<template>
  <v-tabs fixed-tabs dark color="seconday" background-color="primary">
    <v-tab v-for="tab in tabs" :key="tab.text" :to="tab.to">
      <v-icon left :small="$vuetify.breakpoint.smAndUp"
        >mdi-{{ tab.icon }}</v-icon
      >{{ $vuetify.breakpoint.xs ? '' : tab.text }}
    </v-tab>
  </v-tabs>
</template>

<style lang="scss" scoped></style>
