<script>
export default {
  name: 'media-page',
  data: () => ({
    // activeTab: 'documents',
    tabs: [
      {
        to: '/cms/media/documents',
        text: 'DOCUMENTS',
        icon: 'text-box-multiple-outline',
        value: 'documents'
      },
      {
        to: '/cms/media/images',
        text: 'IMAGES',
        icon: 'image-multiple',
        value: 'images'
      }
    ]
  }),
  computed: {
    activeTab() {
      return this.$route?.params?.view || null
    }
  }
}
</script>
<template>
  <v-container>
    <v-card>
      <v-card-title class="d-flex align-end justify-space-between pt-0">
        <v-sheet>
          <v-tabs v-model="activeTab" color="primary">
            <v-tab
              v-for="tab in tabs"
              :key="tab.text"
              :to="tab.to"
              :value="tab.value"
              class="primary--text"
            >
              <v-icon left :small="$vuetify.breakpoint.smAndUp"
                >mdi-{{ tab.icon }}</v-icon
              >{{ $vuetify.breakpoint.xs ? '' : tab.text }}
            </v-tab>
          </v-tabs>
        </v-sheet>

        <v-btn fab small color="primary"
          ><v-icon>mdi-cloud-upload</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-text-field
          prepend-inner-icon="mdi-file-find-outline"
          solo
          rounded
          class="mt-4"
        ></v-text-field>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style lang="scss" scoped></style>
