<script>
import badge from '@/components/__badge.vue'
import emailListItem from '@/components/email-list-item.vue'

export default {
  name: 'email-card',
  components: { badge, emailListItem },
  props: {
    emails: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<template>
  <v-card scrollable tile>
    <v-card-title class="text-h6 primary--text">
      <badge
        :value="emails.length > 0"
        :badgeContent="`${emails.length}${emails.length >= 20 ? '+' : ''}`"
      >
        <template v-slot:child>
          <v-icon color="primary" title="Unread Email." class="mr-2"
            >mdi-email</v-icon
          >
        </template>
      </badge>
      UNREAD MAIL
    </v-card-title>
    <v-card-text ref="emails" class="scrollable-card secondary">
      <p v-if="emails.length < 1">No unread messages found.</p>
      <template v-for="email in emails">
        <email-list-item
          :key="email.receivedDateTime.toString()"
          :email="email"
        ></email-list-item>
        <v-divider
          inset
          :key="email.receivedDateTime.toString() + 'd'"
        ></v-divider>
      </template>
    </v-card-text>
    <v-card-actions></v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>
.scrollable-card {
  max-height: 35rem;
  overflow-y: auto;
}
</style>
