<script>
export default {
  name: 'badge',
  props: {
    badgeContent: {
      type: [String, Number],
      default: () => ''
    },
    inverseColors: {
      type: Boolean,
      default: () => false
    },
    value: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
<template>
  <!-- :color="inverseColors ? 'secondary' : 'primary'" -->
  <v-badge color="accent" :value="value" class="badge">
    <!-- :value="programList.length > 0" -->
    <slot name="child"> </slot>
    <template v-slot:badge>
      <!-- :class="`${
          inverseColors ? 'primary' : 'secondary'
        }--text font-weight-bold`" -->
      <span class="secondary--text font-weight-bold">
        {{ badgeContent }}
      </span>
    </template>
  </v-badge>
</template>

<style lang="scss" scoped>
// @import '~vuetify/src/components/VBadge/_variables.scss';
// .badge {
//   $badge-font-size: 40px;
//   $badge-height: 79px;
// }
::v-deep .v-badge__badge {
  border-radius: 13px;
  font-size: 15px;
  height: 23px;
  min-width: 23px;
}
</style>
