<script>
import appHeader from '@/components/header.vue'
import searchSheet from '@/components/search-sheet.vue'
import snackbar from '@/components/snackbar.vue'
import { mapState } from 'vuex'
export default {
  name: 'App',
  components: {
    appHeader,
    searchSheet,
    snackbar
  },
  data: () => ({
    scrollTopBtn: false,
    scrollTopOptions: {
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic'
    },
    scrollTopTarget: 0
  }),
  computed: {
    ...mapState({
      appLoading: (state) => state.appLoading,
      alert: (state) => state.alert,
      search: (state) => state.search,
      snackbar: (state) => state.snackbar
    }),
    vuetify() {
      return this.$vuetify
    }
  },
  methods: {
    onScroll(e) {
      this.scrollTopBtn = e?.srcElement?.scrollingElement?.scrollTop > 500
    }
  },
  async mounted() {
    console.log('App mounted')
    // this.$store.dispatch('setAppLoading', false)
  }
}
</script>
<template>
  <v-app>
    <app-header></app-header>
    <v-main v-scroll="onScroll" class="mainView">
      <v-alert dismissible type="warning" :value="!!this?.alert?.id"
        >THIS IS A TEST</v-alert
      >
      <!-- LOADING DIALOG -->
      <!-- TODO: MOVE TO COMPONENT -->
      <v-dialog :value="appLoading" hide-overlay persistent width="300">
        <v-card color="primary">
          <v-card-text class="pa-4 secondary--text">
            Loading...
            <v-progress-linear
              indeterminate
              color="secondary"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <router-view v-if="!appLoading" :key="`rv${$route.path}`"></router-view>
    </v-main>

    <!-- SEARCH SHEET -->
    <search-sheet></search-sheet>

    <!-- SNACKBAR -->
    <snackbar :key="`sb${snackbar.value}`"></snackbar>
    <!-- SCROLL TO TOP BUTTON-->
    <v-fab-transition>
      <v-btn
        app
        fixed
        fab
        bottom
        color="primary"
        right
        v-show="scrollTopBtn"
        @click="$vuetify.goTo(scrollTopTarget, scrollTopOptions)"
      >
        <v-icon color="secondary">mdi-chevron-double-up</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<style>
html {
  overflow: scroll;
  overflow-x: hidden;
}

.mainView {
  /* background-color: #dfdfffbd; */
}
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.hoverPointer:hover {
  cursor: pointer;
}

.mainView::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #010566;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(102, 102, 102, 0);
  border-radius: 10px;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
