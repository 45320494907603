<script>
export default {
  name: 'cms-home'
}
</script>
<template>
  <router-view></router-view>
</template>

<style lang="scss" scoped></style>
